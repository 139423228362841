<template>
  <v-row
    v-if="!isLoading"
    class="match-height"
  >
    <v-col cols="12">
      <Loading
        v-if="loadingImport"
        :full-page="true"
        :color="$vuetify.theme.themes.light.primary"
      />
      <app-card-code
        :title="`${$t('menu.nomenclador')} / ${$t('menu.cars')} / ${$t('menu.oficinaRenta')} / ${$t('lbl.cargaLote')}`"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'oficinas-cars-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              v-if="lote !== null"
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :disabled="!lote.listo"
                :loading="processUpload"
                @click="acceptUpload()"
              >
                <span>{{ $t('btn.update') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
            <v-col
              v-if="lote !== null"
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="canceleUpload()"
              >
                <span>{{ $t('btn.canceleUpload') }}</span>
                <v-icon>{{ icons.mdiCancel }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form class="multi-col-validation">
            <v-row v-if="lote === null">
              <v-col
                cols="12"
                md="6"
              >
                <v-file-input
                  v-model="uploadFile"
                  type="file"
                  accept=".ods, .xls, .xlsx"
                  show-size
                  outlined
                  dense
                  :label="$t('lbl.file')"
                  hide-details
                  @change="setFile"
                ></v-file-input>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <h3 class="mb-3">
                  {{ $t('lbl.orderFileColumn') }} (.ods, .xls, .xlsx)
                </h3>
                <v-img
                  lazy-src="@/assets/images/lotes/rentCar.png"
                  src="@/assets/images/lotes/rentCar.png"
                >
                </v-img>
              </v-col>
            </v-row>
            <v-row>
              <fragment v-if="lote !== null">
                <v-col
                  cols="10"
                  offset-md="1"
                >
                  <v-expansion-panels>
                    <v-expansion-panel v-if="ptosChange.length > 0">
                      <v-expansion-panel-header>
                        <template v-slot:default>
                          <v-row>
                            <v-col cols="4">
                              <h4 class="mt-2">
                                {{ $t('menu.ptoRecogida') }}
                              </h4>
                            </v-col>
                            <v-col cols="1">
                              <v-tooltip
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.problemsPtoRecogida') }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in ptosChange"
                                :key="Math.random() * (index + 1)"
                              >
                                <td width="80%">
                                  {{ item }}
                                </td>
                                <td>
                                  <v-tooltip
                                    top
                                    color="primary"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="modalPto(item)"
                                      >
                                        <v-icon color="primary">
                                          {{ icons.mdiSquareEditOutline }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('btn.edit') }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>

                <fragment v-if="lote.listo">
                  <v-col cols="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-uppercase">
                              {{ $t('menu.oficinas') }}
                            </th>
                            <th class="text-uppercase">
                              {{ $t('menu.ptoRecogida') }}
                            </th>
                            <th class="text-uppercase">
                              {{ $t('lbl.address') }}
                            </th>
                            <th class="text-uppercase">
                              {{ $t('lbl.longitud') }}
                            </th>
                            <th class="text-uppercase">
                              {{ $t('lbl.latitud') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <fragment
                            v-for="(item, index) in lote.lote"
                            :key="index"
                          >
                            <tr>
                              <td>
                                {{ item.oficina }}
                                <!--<v-btn
                                  icon
                                  @click="showItem(index)"
                                >
                                  <v-icon>
                                    {{ showRow && index === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                                  </v-icon>
                                </v-btn>-->
                                <v-tooltip
                                  top
                                  color="error"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="deleteItem(item)"
                                    >
                                      <v-icon color="error">
                                        {{ icons.mdiDeleteOutline }}
                                      </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t('btn.delete') }}</span>
                                </v-tooltip>
                              </td>
                              <td>
                                <span v-if="item.namePto">
                                  <span v-if="item.namePto.id">
                                    {{ ptos.filter(e => e.id === item.namePto.id)[0].name }}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <span v-if="item.address">
                                  {{ item.address }}
                                </span>
                              </td>
                              <td>
                                <span v-if="item.longitude">
                                  {{ item.longitude }}
                                </span>
                              </td>
                              <td>
                                <span v-if="item.latitude">
                                  {{ item.latitude }}
                                </span>
                              </td>
                            </tr>
                          </fragment>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </fragment>
              </fragment>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDeleteItem"
      scrollable
      max-width="380px"
    >
      <v-card
        v-if="model.name"
        max-height="400px"
      >
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.addDelete', { n: model.name.name }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDeleteItem = !isDialogVisibleDeleteItem"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loadingDelete"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR UPDATE ALL -->
    <v-dialog
      v-model="isDialogUpdate"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.acceptChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.processUpload', { n: 'oficinas de renta de autos' }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogUpdate = !isDialogUpdate"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="cancelarUpload"
            @click="confirmUpdateAll()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- MOSTRAR CANCEL UPLOAD -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.cancele')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.canceleUpload', { n: 'oficinas de renta' }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="cancelarUpload"
            @click="confirmCancele()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ADD CHANGE NOM -->
    <v-dialog
      v-model="isAddChange"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.insert')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addChange', {
              n: type === 'pto' ? model.namePto.name : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddChange = !isAddChange"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddChange()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL REMPLACE-->
    <v-dialog
      v-model="isAddRemplace"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.remplace')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addRemplace', {
              m: type === 'pto' ? model.namePto.name : '',
              n:
                type === 'pto'
                  ? model.namePto.id
                    ? itemsPtos.filter(e => e.id === model.namePto.id).length > 0
                      ? itemsPtos.filter(e => e.id === model.namePto.id)[0].name
                      : ''
                    : ''
                  : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddRemplace = !isAddRemplace"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddRemplace()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL ASOCIATE-->
    <v-dialog
      v-model="isAddAsociate"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.asociate')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addAsociate', {
              m: type === 'pto' ? model.namePto.name : '',
              n:
                type === 'pto'
                  ? model.namePto.id
                    ? itemsPtos.filter(e => e.id === model.namePto.id).length > 0
                      ? itemsPtos.filter(e => e.id === model.namePto.id)[0].name
                      : ''
                    : ''
                  : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddAsociate = !isAddAsociate"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddAsociate()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL DELETE VALUE-->
    <v-dialog
      v-model="isAddDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addDelete', {
              n: type === 'pto' ? model.namePto.name : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddDelete = !isAddDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CHANGE PTOS -->
    <v-dialog
      v-model="isChangePto"
      max-width="750px"
    >
      <v-card height="300px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.namePto"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="model.namePto.name"
                :label="$t('menu.ptoRecogida')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="model.namePto.id"
                :items="itemsPtos"
                :search-input.sync="searchPtos"
                hide-details
                hide-selected
                :label="$t('menu.ptoRecogida')"
                outlined
                dense
                item-text="name"
                item-value="id"
                clearable
                single-line
                @click:clear="clearPto()"
                @change="setPto()"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.ptoRecogida') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col
              v-if="iconSelect !== null && iconSelect !== ''"
              cols="12"
              md="1"
              class="text-center pt-4"
            >
              <v-icon
                v-if="model.namePto.icon"
                size="30"
                color="primary"
                v-text="iconSelect"
              ></v-icon>
            </v-col>
            <v-col
              v-if="model.namePto"
              cols="12"
              :md="iconSelect != null ? '5' : '6'"
            >
              <v-autocomplete
                v-model="model.namePto.icon"
                :items="itemsIcons"
                :search-input.sync="searchIcons"
                hide-details
                hide-selected
                label="Icon"
                outlined
                dense
                item-text="name"
                item-value="name"
                clearable
                single-line
                @click:clear="iconSelect = null"
                @change="changeIcons"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Icon
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-group color="primary">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon v-text="item.name"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="pl-5">
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-row
            v-if="model.namePto"
            class="ml-2"
          >
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                outlined
                @click="isChangePto = !isChangePto"
              >
                {{ $t('btn.close') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
              offset-md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    v-on="on"
                    @click="showAdd('pto')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.namePto.id"
                    v-on="on"
                    @click="showRemplace('pto', model.namePto)"
                  >
                    <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.remplace') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="primary"
                    outlined
                    class="ml-5"
                    :disabled="!model.namePto.id"
                    v-on="on"
                    @click="showAsociate('pto', model.namePto)"
                  >
                    <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.asociate') }}</span>
              </v-tooltip>

              <v-tooltip
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    v-bind="attrs"
                    color="error"
                    outlined
                    class="ml-5"
                    v-on="on"
                    @click="showDelete('pto', model.namePto.name)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
// eslint-disable-next-line import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiPlaylistEdit,
  mdiCancel,
  mdiInformationOutline,
  mdiListStatus,
  mdiFileReplaceOutline,
  mdiLinkVariantPlus,
  mdiMenuDown,
  mdiMenuRight,
} from '@mdi/js'

export default {
  components: {
    AppCardCode,
    Loading,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiPlaylistEdit,
        mdiCancel,
        mdiInformationOutline,
        mdiListStatus,
        mdiFileReplaceOutline,
        mdiLinkVariantPlus,
        mdiMenuDown,
        mdiMenuRight,
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      transmisiones: [],
      marcas: [],
      combustibles: [],
      caracteristicas: [],
      modelCaracteristicas: [],
      model: {},
      edad_min: {},
      loading: false,
      loadingDelete: false,
      loadingImport: false,

      items: [],

      isDialogVisible: false,
      isDialogVisibleDelete: false,
      isDialogVisibleDeleteItem: false,
      isDialogUpdate: false,
      nameOlds: [],
      newNameOld: {},
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      ptos: [],
      itemsPtos: [],
      searchPtos: null,
      modelServicios: [],
      uploadFile: null,
      uploadingFile: false,
      processUpload: false,
      cancelarUpload: false,
      isChangePto: false,
      isAddChange: false,
      isAddRemplace: false,
      isAddAsociate: false,
      isAddDelete: false,
      addChange: false,
      type: null,
      changing: false,
      lote: null,

      ptosChange: [],
      valueRemplace: null,
      valueCsv: null,
      idRemplace: null,

      iconito: null,
      iconSelect: null,
      iconos: [],
      itemsIcons: [],
      searchIcons: null,
      showRow: false,
      indexRow: -1,
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
    }),
  },
  watch: {
    searchIcons(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterIcons(val.toLowerCase())
      } else {
        this.itemsIcons = []
      }
    },
    searchPtos(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        this.filterPtos(val.toLowerCase())
      }
    },
  },

  created() {
    this.getNomAll()
    this.getLote()
  },
  methods: {
    ...mapMutations(['setOnlyShow']),
    getLote() {
      this.axios
        .get('lotes-oficinas-rent', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          // console.log(res)

          this.lote = res.data.data.data

          if (this.lote) {
            this.ptosChange = res.data.data.data.pts
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
    filterIcons(v) {
      this.itemsIcons = []
      if (v === '') {
        this.itemsIcons = []
      } else {
        this.itemsIcons = this.iconos.filter(e => e.name.toLowerCase())
      }
    },
    filterPtos(v) {
      this.itemsPtos = []
      if (v === '') {
        this.itemsPtos = []
      } else {
        this.itemsPtos = this.ptos.filter(e => e.name.toLowerCase())
      }
    },
    getNomAll() {
      this.getIcons()
      this.getPtos()
    },
    getPtos() {
      this.axios
        .get('nom_tags_pto_recogida_cars?per_page=10000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.ptos = res.data.data
          }
        })
    },
    getIcons() {
      this.axios
        .get('nom_icons/mdi', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.iconos = res.data.data
        })
    },
    changeIcons() {
      if (this.model.namePto.icon) {
        this.iconSelect = this.model.namePto.icon
      }
    },
    setPto() {
      if (this.model.namePto) {
        if (this.model.namePto.id) {
          const fff = this.ptos.filter(e => e.id === this.model.namePto.id)
          if (fff.length > 0) {
            this.itemsIcons.push(this.iconos.filter(e => e.name === fff[0].icon)[0])
            this.model.namePto.icon = fff[0].icon
            this.iconSelect = fff[0].icon
          }
        }
      }
    },
    clearPto() {
      // this.iconSelect = null
      this.model.namePto.icon = null
    },
    canceleUpload() {
      this.isDialogVisibleDelete = true
    },
    confirmCancele() {
      this.cancelarUpload = true
      this.axios
        .delete(`lotes-oficinas-rent/${this.lote.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            }
          } else {
            this.getLote()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.resetFile())
    },
    modalPto(item) {
      this.type = 'pto'
      this.model = {
        namePto: {
          name: item,
          icon: null,
          valueCsv: item,
        },
      }
      this.isChangePto = true
    },
    showAdd(type) {
      this.type = type
      this.isAddChange = true
    },
    showRemplace(type, newValue) {
      this.type = type
      this.valueRemplace = newValue.name
      this.valueCsv = newValue.valueCsv
      this.idRemplace = newValue.id
      this.isAddRemplace = true
    },
    showAsociate(type, newValue) {
      this.type = type
      this.valueRemplace = newValue.name
      this.valueCsv = newValue.valueCsv
      this.idRemplace = newValue.id
      this.isAddAsociate = true
    },
    showDelete(type, newValue) {
      this.type = type
      this.valueRemplace = newValue
      this.isAddDelete = true
    },
    deleteItem(item) {
      this.isDialogVisibleDeleteItem = true
      this.model = {
        name: {
          id: item.id,
          name: item.oficina,
        },
      }
    },
    confirmDelete() {
      this.loadingDelete = true
      const json = {
        id: this.model.name.id,
      }

      this.axios
        .post(`lotes-oficinas-rent/delete-item/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .finally(() => this.resetFile())
    },
    acceptUpload() {
      this.isDialogUpdate = true
    },
    confirmUpdateAll() {
      // this.cancelarUpload = true
      this.loadingImport = true
      this.axios
        .post(
          'lotes-oficinas-rent/process-all',
          { id: this.lote.id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            }
          } else {
            this.getLote()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.resetFile())
    },
    confirmAddChange() {
      this.addChange = true
      this.axios
        .post(
          `lotes-oficinas-rent/add-change/${this.lote.id}`,
          { type: this.type, changes: this.model },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    confirmAddRemplace() {
      this.addChange = true
      const json = {
        newValue: this.valueRemplace,
        valueCsv: this.valueCsv,
        idValue: this.idRemplace,
        type: this.type,
      }

      if (this.model.namePto) {
        if (this.model.namePto.icon) {
          json.icon = this.model.namePto.icon
        }
      }

      this.axios
        .post(`lotes-oficinas-rent/add-remplace/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    confirmAddAsociate() {
      this.addChange = true
      const json = {
        newValue: this.valueRemplace,
        valueCsv: this.valueCsv,
        idValue: this.idRemplace,
        type: this.type,
      }

      if (this.model.namePto) {
        if (this.model.namePto.icon) {
          json.icon = this.model.namePto.icon
        }
      }

      this.axios
        .post(`lotes-oficinas-rent/add-asociate/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    confirmAddDelete() {
      this.addChange = true
      const json = {
        newValue: this.valueRemplace,
        type: this.type,
      }

      this.axios
        .post(`lotes-oficinas-rent/add-delete/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getNomAll()
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },

    setFile() {
      if (this.uploadFile) {
        this.loadingImport = true
        const formData = new FormData()
        formData.append('json', this.uploadFile)
        this.axios
          .post('import-json/rent-car', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            if (response.data.success === false) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            } else {
              this.$toast.success(this.$t('msg.fileUpload'))
            }
            this.getLote()
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => this.resetFile())
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    resetFile() {
      this.uploadFile = null
      this.model = {}
      this.logo = null
      this.type = null
      this.iconito = null
      this.iconSelect = null
      this.uploadingFile = false
      this.loadingImport = false
      this.isDialogVisibleDeleteItem = false
      this.isDialogUpdate = false
      this.loadingDelete = false
      this.cancelarUpload = false
      this.isDialogVisibleDelete = false
      this.isChangePto = false
      this.isAddChange = false
      this.isAddRemplace = false
      this.addChange = false
      this.valueRemplace = null
      this.valueCsv = null
      this.idRemplace = null
      this.isAddAsociate = false
      this.isAddDelete = false

      this.searchPto = null
      this.searchIcons = null
    },
  },
}
</script>
<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}
</style>
